.Title{
  
  &__SVG{
    height: 200px;
  }
  &__Text{
    font-size: 26px;
    text-align: center;
    opacity: 0;
    transition: opacity .1s ease-out;

    &.appear{
      opacity: 1;
    }
  }
  &__Animated_Heart{
    font-size: 28px;
    animation: blink 1s steps(1) infinite;
  }

  @media (max-width: 640px) {
    &__SVG{
      height: 60px;
      padding: 0 2rem;
    }
    &__Text{
      font-size: 12px;
      
    }
    &__Animated_Heart{
      font-size: 16px;
     
    }
    
  }
}


@keyframes blink {
  50% {
    opacity: 0;
  }
}