
.Page{
  &__Landscape,
  &__Loading{
    background: #2D5EF0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 1;
    transition: opacity .2s ease-out;

    &.hide{
      opacity: 0;
    }
    p{
      margin-top: 1rem;
    }
  }

  &__Landscape{
    &.hide{
      display: none;
    }
  }

  &__Intro{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &__Title{
      margin: 80px 0 110px;

      @media (max-height: 640px) {
        margin: 20px 0 50px;
      }
    }
  }

  &__Game{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: translate3d(-100%, 0, 0);
    overflow: hidden;
    transition: transform .25s;
    
    &.show{
      transform: translate3d(0, 0, 0);
    }

    &__Play_Btn{
      background-color: #FFBCF1;
      border: 0;
      font-size: 115px;
      color: #FF4155;
      padding: 68px 0;
      width: 100%;
      margin: 0;
      font-family: 'VTF MisterPixel';
      cursor: pointer;

      &:active,
      &:focus{
        outline: none;
      }

      @media (max-width: 1024px) {
        font-size: 60px;
        padding: 32px 0;
      }
        
    }

  

    
  }
}

