

body .Game{

  .scroller{
    width: 100%;
    height: 100%;
    transform: translate3d(0%, 0, 0);

    @media (min-width: 769px) {
      display: flex;
    }
  }

  @media (max-width: 769px) {
    transition: transform .2s ease-in-out;

    &.appear{
     .scroller{
      transform: translate3d(-100%, 0, 0);
     }
    }
  }
  
    
  &__Rules{

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    

    @media (max-width: 769px) {
      width: 100%;
    }

    &__Wrapper{
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: 30px 72px;
      box-sizing: border-box;
      flex-direction: column;
      justify-content: center;

      p{
        font-size: 26px;
        line-height: 32px;
      }
  
      p + p {
        margin-top: 2.5rem;
      }

      @media (max-width: 1441px ) {
        padding: 32px;

        p{
          font-size: 1rem;
          line-height: 1.30rem;
        }
    
        p + p {
          margin-top: 1.25rem;
        }
      }
      @media (max-width: 1025px) {
        padding: 32px;

        p{
          font-size: 1rem;
          line-height: 1.30rem;
        }
    
        p + p {
          margin-top: 1.25rem;
        }
      }

     
        
    }

   
  }
  &__Canvas{
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    flex-direction: column;
    padding: 2rem;
    /* width: 100%; */
    #snake__game{
      transform: scale(0.75);
      // transform-origin: top center;
    }
    @media (max-width: 769px) {
      width: 100%;
      transform: translate3d(100%, 0, 0);
    }

    &__Control{
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      
      width: 100%;
      opacity: .2;
      overflow: hidden;

      display: none;


      &.hide{
        display: none;
      }

      button{
        margin: 0;
        background: #000;
        color: #fff;
        border: 0;
        padding: 20px;
        // border: 4px solid #2d5ef0;
        width: 33.33%;

        &:hover,
        &:active {
          background: rgb(51, 51, 51);
          animation-duration: 0.5s;
          animation-name: touch;
          animation-iteration-count: 1;
          animation-timing-function: linear;
          animation-fill-mode: forwards;
        }
      }

      div{
        margin: 0 0.25rem;
        width: calc(33.33% - 0.5rem);
        button{
          width: 100%;
          height: 103px;
        }

        button + button{
          margin-top: 0.25rem;
        }
      }

      .control{
        img{
          height: 40px;
        }

        
        &--up{}
        &--down{
          img{
            transform: rotate(180deg);
          }
        }
        &--right{
          img{
            transform: rotate(90deg);
          }
        }
        &--left{
          img{
            transform: rotate(-90deg);
          }
        }
      }
    }
  }


  


  @media (max-width: 769px) {
    &__Rules,
    &__Canvas{
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  
  }
}


@keyframes touch {
  0% {
    background: rgb(51, 51, 51);
  }

  to {
    background: #000;
  }
}

body.touch .Game__Canvas__Control{
  display: flex;
  &.hide{
    display: none;
  }

}