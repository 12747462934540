
.Snake_Animation{
  width: 100%;
  height: 130px;
  overflow: hidden;
  position: relative;
  flex: none;
  &--alt{
    transform: scale(-1, 1);
  }

  &__Bottle{
    position: absolute;
    
    top: 0;
    left: 20%;
    height: 132px;

    

    &.animation{
      animation-name: bottle_eat;
      animation-duration: 6s;

      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }


    &--alt{
      transform: scale(-1, 1);
    }

    @media (max-width: 640px) {
      top: 30px;
      left: 27%;
      height: 61px;
    }
  }

  &__SVG{
    position: relative;
    top: -8px;
    left: 9px;

    @media (max-width: 640px) {
      position: relative;
      top: 37px;
      left: 4px;
    }
  }

  &__Container{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transform: translate3d(100%, 0, 0);
    // transform: translate3d(50%, 0, 0);
    display: flex;

    

    &.animation{
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      animation-duration: 6s;
      animation-name: slidein;
    }

    svg{
      width: 173px !important;
      height: 159px !important;
    }

    &__Asset{
      width: 774px;
      flex: none;
    }


    @media (max-width: 640px) {
      width: 180%;

      svg{
        width: 67px !important;
        height: 61.5px !important;
      }
  
      &__Asset{
        width: 300px;
      }
    }
  }
}

@keyframes slidein {
  from {
    transform: translate3d(100%, 0, 0);
  }

  to {
    transform: translate3d(-70%, 0, 0);
  }
}


@keyframes bottle_eat {
  0%{opacity: 1;}
  47%{ opacity: 1;}
  48%{opacity: 0;}
  100%{opacity: 0;}
}