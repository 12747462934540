@font-face {
  font-family: 'VTF MisterPixel Tools';
  src: url('./assets/fonts/VTFMisterPixel-Tools.woff2') format('woff2'),
      url('./assets/fonts/VTFMisterPixel-Tools.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
    font-family: 'VTF MisterPixel';
    src: url('./assets/fonts/VTFMisterPixel.woff2') format('woff2'),
        url('./assets/fonts/VTFMisterPixel.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
  font-family: 'ArcaMajora3';
  src: url('./assets/fonts/ArcaMajora3-Bold.woff2') format('woff2'),
      url('./assets/fonts/ArcaMajora3-Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ArcaMajora3';
  src: url('./assets/fonts/ArcaMajora3-Heavy.woff2') format('woff2'),
      url('./assets/fonts/ArcaMajora3-Heavy.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}


.alt-font{
  font-family: 'VTF MisterPixel';
}

.tool-font{
  font-family: 'VTF MisterPixel Tools';
}

.text{
  font-family: 'ArcaMajora3';
  font-weight: normal;
  color: #FFBBF1;
}

.blue{
  color: #2D5EF0;
}

.uppercase{
  text-transform: uppercase;
}

body {
  background: #2D5EF0;
  font-family: 'VTF MisterPixel';
  overflow: hidden;
}

*{
  box-sizing: border-box;
}

@import './scss/Page';
@import './scss/Title';
@import './scss/SnakeAnimation';
@import './scss/Game';


.container{
  display: flex;
  width: 100%;
  height: 100vh;
  /* If you need to support browser without CSS var support (<= IE11) */
  // height: calc(100vh - var(--vh-offset, 0px));
  /* enable vh fix */
}

.side-block{
  background: #FF4155;
  width: 50%;
  height: 100%;
 
    
}

.side-block + .side-block{

  background: #2D5EF0;
}

#snake__game{
  background: #FF4155;
  padding: 22px;
  // margin: 100px auto 0;
  // width: 380px;
  // display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: relative;

  .header_game{
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-bottom: 4px solid #FFBBF1;
    margin-bottom: 10px;
  }
  
  .number{
    margin-bottom: 8px;
    height: 20px;

    img{
      height: 20px;
    }

    img[src] + img[src]{
      margin-left: 5px;
    }
  }

  .bottle_score,
  .bottle_counter{
    display: flex;
    align-items: flex-start;
  }

  canvas{
    border: 8px solid #FFBBF1;
    margin: 0 auto;
  }

  .panel{

    &-wrapper{
      position: absolute;
      top: 42px;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    border: 4px solid #FFBBF1;
    background-color: #FF4155;
    padding: 8px;
    

    color: #FF4155;

    &.hide{
      display: none;
    }

    .wrapper{
      background-color: #FFBBF1;
      text-align: center;
      padding: 18px 18px 14px;
    }

    h2{
      text-transform: uppercase;
      font-size: 2rem;
      margin-bottom: 1rem;
    }

    .text-game{
      font-size: 2rem;
    }

    form{
      font-family: 'VTF MisterPixel';
      input{
        border: 0;
        background-color: #FFBBF1;
        border-bottom: 2px solid #FF4155;
        font-family: 'VTF MisterPixel';
        font-size: 1rem;
        color: #FF4155;
        width: 137px;
      }

      
    }

    button{
      margin-top: 1rem;
      font-family: "VTF MisterPixel";
      border: 2px solid #FF4155;
      margin-top: 1rem;
      color: #FF4155;
      font-size: 1rem;
      box-shadow: -2px 2px #FF4155, -4px 4px #FF4155;
      padding: 4px;
      cursor: pointer;
      background-color: #FFBBF1;
      transition: all .1s ease-out;
      text-transform: uppercase;
      span{
        padding: 5px 24px;
        border: 2px solid #FFBBF1;
        display: inline-block;  
      }
      &:hover{
        box-shadow: 0px 0px #FF4155, 0px 0px #FF4155;
        background-color: #FF4155;
        // border-color: #FFBBF1;
        color: #FFBBF1;
      }
    }
  }

  .new-score{
    .score{
      font-size: 20px;
      &-wrapper{
        text-transform: uppercase;
        font-size: 12px;
        margin-bottom: 1rem;
      }
    }
  }

  .high-score{
    ul{
      li{
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .pause-btn{
    border: 0;
    background-color: transparent;
    margin-bottom: 8px;
    cursor: pointer;
    padding: 0;
    margin: 0;
    img{
      height: 20px;
    }
  }
}


.Paulette__Logo{
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 9999;

  &__Link{
    border: 0;
    padding: 0;
    margin: 0;
    &:hover,
    &:focus,
    &:active{
      outline: none;
    }
  }
}

.anim-hand{
  animation-iteration-count: infinite;
  animation-timing-function: steps(5);
  animation-duration: 3s;
  animation-name: hand;
  display: inline-block;

}
.anim-play{
  animation: blink 1s steps(1) infinite;
}

@keyframes hand {
  0% {
    transform: translate3d(-10%, 0, 0);
  }

  50% {
    transform: translate3d(10%, 0, 0);
  }
  
  100%{
    transform: translate3d(-10%, 0, 0);
  }
}